<template>
    <div class="content">
        <div class="right" style="padding: 2vh">
        <breadCrumb :siderContent="siderName" />
        <div class="searchForm">
          关键词:
          <a-input style="width:20vw!important;"
            v-model="keywords"
            v-decorator="['keywords']"
            placeholder="姓名/手机号/卡号/订单号"
          />
          <a-range-picker 
            :placeholder="['开始时间', '结束时间']"
            @change="handleChange"
            v-model="time"
            style="margin:0 2vw 0 1vw"
            >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
          <a-button class="searchBtn" @click="searchMember()">查询</a-button>
          <a-button class="cancleBtn" id="again" @click="againMember">重置</a-button>
          <br />
          <a-divider style="margin: 1vh"/>
        </div>       
        <div>
          <a-table
            :columns="columns"
            :data-source="members"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            style="width: 100%; margin-top: 1vh"
            :pagination="{
              current: current,
              total: total,
              pageSize: 9,
            }"
            @change="handleTableChange"
            :locale="{emptyText: '暂无数据'}"
          >
            <span slot="action" slot-scope="text, record">
              <span  class="Recharge" @click="showmodalTui(record)">退款</span>
            </span>
          </a-table>
        </div>
      </div>
    <payBack :isShow="showPayback" :fData="clickEveryGood" @changeModal="changePayBack" @choose="updateListba"/>
    </div>
</template>
<script>
import payBack from "./payback.vue";
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import {
  rechargeList,//会员充值
} from "@/request/mis";

const columns = [
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
  {
    title: "姓名",
    dataIndex: "nick_name",
  },
  {
    title: "手机号",
    dataIndex: "mobile",
  },
  {
    title: "卡号",
    dataIndex: "card_number",
  },
  {
    title: "充值订单号",
    dataIndex: "order_no",  
  },
  {
    title: "充值方式",
    dataIndex: "type_desc",
    ellipsis: true,
  },
  {
    title: "充值金额",
    dataIndex: "pay_price",
    ellipsis: true,
  },
   {
    title: "赠送金额",
    dataIndex: "gift_money",
    ellipsis: true,
  },
   {
    title: "付款时间",
    dataIndex: "pay_time",
   
  },
];

export default {
  data() {
    return {
      members: [], //表格数据
      columns, //表头数据
      siderName: "充值退款", //sider切换内容
      clickEveryGood: "",//点击时的每条会员信息
      selectedRowKeys: [], //选中数据
      keywords: "", //搜索关键词参数
      current: 1, //表格当前页
      total: 0, //表格数据条
      showPayback:false,//退款modal
      time:[],//时间数组
      start_time:'',//开始时间
      end_time:'',//结束时间
      keywordsSure:'',//最终姓名手机号桌号
      start_timeSure:'',//最终开始时间
      end_timeSure:'',//最终结束时间
    };
  },
  mounted() {
    this.membersRuquest();
  },
  methods: {
    againMember(){
      this.keywords="";
      this.start_time='';
      this.end_time='';
      this.keywordsSure="",//最终姓名手机号桌号
      this.start_timeSure="",//最终开始时间
      this.end_timeSure="",//最终结束时间
      this.time=[],
      this.current=1;
      this.membersRuquest();
    },
    membersRuquest(){//会员列表请求
        rechargeList({
        keywords:this.keywordsSure,
        start_time:this.start_timeSure,
        end_time:this.end_timeSure,
        listRows:9,
        page: this.current,
      }).then((res) => {
        if(res.data.status===200){
          const members = res.data.data.list.data && res.data.data.list.data;
          this.members = members;
          this.total = res.data.data.list.total && res.data.data.list.total;
        }     
      });
    },
    searchMember() {//点击查询按钮
      this.keywordsSure=this.keywords,//最终姓名手机号桌号
      this.start_timeSure=this.start_time,//最终开始时间
      this.end_timeSure=this.end_time,//最终结束时间
      this.current=1;
      this.membersRuquest();
    },
    handleTableChange(e) {
      //点击分页
      this.current = e.current;
      this.membersRuquest();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    showmodalTui(record){ //点击退款
      this.showPayback=true;
      this.clickEveryGood=record;
    },
    changePayBack(){//关闭退款modal
      this.showPayback=false;
    },
    updateListba(){ //退款后更新列表
      this.membersRuquest();
    },

    handleChange(value,dateString) { //获取时间
     if(dateString){
       this.time=dateString;
       this.start_time=dateString[0] && dateString[0];
       this.end_time=dateString[1] && dateString[1];
     }
    },
  },
  components: {
    breadCrumb,
    payBack,
  },

  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
};
</script>
<style lang="less" scoped>
* {
  padding: 0;
  box-sizing: border-box;
}
.mis {
  width: 100%;
  display: flex;
  height: 95.4vh;
}
.left {
  width: 17vw;
  background: rgba(46, 62, 78, 1);
}
.ipt {
  width: 26vh;
  height: 3.9vh;
  padding: 1vh;
  margin: 0vh 3vh 0 0vh;
}

.myUse {
  color: #d0d0d0;
}

#again{
  margin-left: 1vh;
}
/deep/.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 5px 0;
  font-size: 1.8vh;
  text-align: center;
}
/deep/.ant-table-thead > tr > th{
  background: rgba(248, 248, 255, 1);
}
/deep/.ant-table-tbody > tr > td {
  background: white;
}
/deep/.ant-select-enabled {
  width: 16.5vh !important;
}
/deep/.ant-select-selection--single {
  width: 16.5vh;
  height: 3.9vh;
}
/deep/.ant-select-selection__rendered {
  line-height: 3.75vh;
}
/deep/.ant-table-tbody > tr > td {
  padding: 8px 8px;
  font-size:1.8vh;
}
/deep/th:nth-child(1) {
  width: 5vw;
}
/deep/th:nth-child(2) {
  width: 5vw;
}
/deep/th:nth-child(3) {
  width: 7vw;
}
/deep/th:nth-child(4) {
  width: 9vw;
}
/deep/th:nth-child(5) {
  width: 11vw;
}
/deep/th:nth-child(6) {
  width: 5vw;
}
/deep/th:nth-child(7) {
  width: 6vw;
}
/deep/th:nth-child(8) {
  width: 6vw;
}
/deep/th:nth-child(9) {
  width: 10vw;
}
/deep/td {
  font-size: 1.6vh;
  text-align: center;
}
.ant-table-wrapper {
  zoom: 1;
}
.ant-row {
  margin: 1.5vh;
}
/deep/.card-title {
  margin: 1.5vh;
  font-size: 2vh;
  margin-left: 0vw;
  font-weight: bold;
}
.Recharge{
  color: #2a82e4;
  cursor: pointer;
 
} 
.jin{
  color:#d0d0d0 !important;
} 
.right{
  background: rgba(248, 248, 255, 1);
}
.content{
  background: #f8f8ff;
}  
/deep/.ant-input{
  height: 3.9vh;
}  
</style>
